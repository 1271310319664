import React, {Component, ReactNode} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import {DropdownOptionProps, DropdownProps, Dropdown, ColorPicker} from 'wix-ui-tpa/cssVars';
import {RadioButtonChangeEvent, RadioButtonClickEvent} from 'wix-ui-core';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import s from './ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {classes as dropdownOptionStyles, style as styleDropdown} from './DropdownOption.st.css';
import {classes as colorOptionStyles} from './ColorOption.st.css';
import classNames from 'classnames';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {Experiments, ProductOptionsDiplayLimit} from '../../../../constants';
import {RevealAnimation} from '../../RevealAnimation/RevealAnimation';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';

type IProductOption = IProduct['options'][0];

export interface ProductOptionsChangeData {
  optionType: ProductOptionType;
}

export interface IProductOptionsProps extends IGalleryGlobalProps {
  product: IProduct;
  selectionIds: number[];

  onSelectionIdsChange(
    optionSelections: IProductOptionsProps['selectionIds'],
    changeData: ProductOptionsChangeData
  ): void;

  variantsAvailability: IProductSelectionAvailabilityMap;
  isItemHovered: boolean;
}

interface OptionRenderParams {
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
  url?: string;
}

export enum DataHook {
  ProductOption = 'product-option',
  DropdownOption = 'product-options-dropdown',
  ColorOption = 'product-options-color',
  ColorOptionMore = 'product-options-color-more',
  OptionWrapper = 'product-option-wrapper',
  RevealAnimationWrapper = 'product-option-reveal-animation-wrapper',
}

const getAllVisibleDropdownOptions = (options: IProductOption[]): IProductOption[] => {
  const colorOptsCount = getAllVisibleColorOptions(options).length;
  const slotsForDropdowns = ProductOptionsDiplayLimit.totalLimit - colorOptsCount;
  return options.filter(({optionType}) => optionType === ProductOptionType.DROP_DOWN).slice(0, slotsForDropdowns);
};

const getAllVisibleColorOptions = (options: IProductOption[]): IProductOption[] => {
  return options
    .filter(({optionType}) => optionType === ProductOptionType.COLOR)
    .slice(0, ProductOptionsDiplayLimit.colorPickersLimit);
};

const getAllVisibleOptions = (options: IProductOption[]): IProductOption[] => {
  return [...getAllVisibleColorOptions(options), ...getAllVisibleDropdownOptions(options)];
};

export class ProductOptions extends Component<IProductOptionsProps & IProvidedTranslationProps> {
  private readonly sealColorChangeHandler =
    (optionsSelections: OptionRenderParams['option']['selections']) =>
    (event: RadioButtonChangeEvent | RadioButtonClickEvent) => {
      const {selectionIds, onSelectionIdsChange} = this.props;

      const pickedSelectionId = optionsSelections.find(({value}) => value === event.value).id;

      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelections.find(({id}) => id === selectionId)),
        pickedSelectionId,
      ];

      onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.COLOR,
      });
    };

  private readonly sealDropdownChangeHandler =
    (optionsSelectionIds: number[]): DropdownProps['onChange'] =>
    (selected) => {
      const {selectionIds, onSelectionIdsChange} = this.props;

      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelectionIds.includes(selectionId)),
        parseInt(selected.id, 10),
      ];

      onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.DROP_DOWN,
      });
    };

  private readonly renderColorOption = ({option: {id, selections, title}, availability, url}: OptionRenderParams) => {
    const {useExperiments, styles, stylesParams, isLiveSiteMode, isPreviewMode} = this.props.globals;
    const {selectionIds, t} = this.props;
    const galleryProductOptionsLimit = useExperiments.enabled(Experiments.GalleryProductOptionsLimit);

    let filteredSelections = selections.filter((selection) => availability[selection.id].isVisible);
    let moreProductOptionsSwatchesCount: number;

    if (galleryProductOptionsLimit) {
      const shouldLimitProductOptions = styles.get(stylesParams.gallery_shouldLimitProductOptions);
      if (shouldLimitProductOptions) {
        const productOptionSwatchesLimit = styles.get(stylesParams.gallery_productOptionSwatchesLimit);
        moreProductOptionsSwatchesCount = Math.max(filteredSelections.length - productOptionSwatchesLimit, 0);
        filteredSelections = filteredSelections.slice(0, productOptionSwatchesLimit);
      }
    }

    const colorItems = filteredSelections.map((selection) => {
      const isDisabled = !availability[selection.id].isSelectable;
      return (
        <ColorPicker.Item
          key={selection.id}
          aria-label={selection.description}
          value={selection.value}
          disabled={isDisabled}
          isCrossedOut={isDisabled}
          checked={selectionIds.includes(selection.id)}
        />
      );
    });
    const colorOptionsDefaultSizeViewer = useExperiments.enabled(Experiments.ColorOptionsDefaultSizeViewer);

    return (
      <div key={id} data-hook={DataHook.ProductOption}>
        <ColorPicker
          className={classNames({
            [colorOptionStyles.colorOption]: colorOptionsDefaultSizeViewer,
            [colorOptionStyles.withAutoWidth]: galleryProductOptionsLimit,
            [colorOptionStyles.colorOptionOldDefault]: !colorOptionsDefaultSizeViewer,
          })}
          onChange={this.sealColorChangeHandler(selections)}
          data-hook={DataHook.ColorOption}
          aria-label={title}>
          {colorItems}
        </ColorPicker>
        {galleryProductOptionsLimit && !!moreProductOptionsSwatchesCount && (
          <a
            href={isLiveSiteMode || isPreviewMode ? url : null}
            data-hook={DataHook.ColorOptionMore}
            className={colorOptionStyles.moreColorsCounter}>
            {t('gallery.colorSwatch.numChoicesLeft.label', {numChoiceLeft: moreProductOptionsSwatchesCount})}
          </a>
        )}
      </div>
    );
  };

  private readonly renderDropdownOption = ({option, availability}: OptionRenderParams) => {
    const {useExperiments} = this.props.globals;
    const galleryProductOptionsAndQuantityRoundCornersInViewer = useExperiments.enabled(
      Experiments.GalleryProductOptionsAndQuantityRoundCornersInViewer
    );

    const {title, selections} = option;
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));

    return (
      <div data-hook={DataHook.ProductOption}>
        <Dropdown
          className={
            /* istanbul ignore next */
            classNames(
              styleDropdown(
                /* istanbul ignore next */
                galleryProductOptionsAndQuantityRoundCornersInViewer
                  ? dropdownOptionStyles.dropdownOption
                  : dropdownOptionStyles.dropdownOptionOld,
                {
                  fixDisabledOpacity: useExperiments.enabled(Experiments.FixDropdownOptionsDisabledOpacity),
                }
              )
            )
          }
          data-hook={DataHook.DropdownOption}
          placeholder={title}
          options={options}
          onChange={this.sealDropdownChangeHandler(selections.map((selection) => selection.id))}
        />
      </div>
    );
  };

  private readonly getColorOptionClass = (isNewMargin: boolean): string => (isNewMargin ? s.color : s.oldColor);

  private readonly renderAllColorOptions = (colorOptions: IProductOption[]) => {
    const {
      variantsAvailability,
      product,
      globals: {productsManifest, useExperiments},
    } = this.props;
    const shouldUseNewMarginAndPadding = useExperiments.enabled(Experiments.EnableQualityOptionsStylingChanges);
    return colorOptions.map((option) => {
      return (
        <div
          key={option.id}
          className={classNames(
            this.getColorOptionClass(shouldUseNewMarginAndPadding),
            this.getVisibilityClassNames(option)
          )}
          data-hook={DataHook.OptionWrapper}>
          {this.renderColorOption({
            url: productsManifest[product.id].url,
            option,
            availability: variantsAvailability[option.id],
          })}
        </div>
      );
    });
  };

  private readonly getVisibilityClassNames = (option: IProductOption) => {
    return classNames({
      [s.lastVisibleOption]: (this.isVisible(option) || this.isFirst(option)) && !this.isNextVisible(option),
    });
  };

  private readonly getDropDownOptionClass = (isNewMargin: boolean): string =>
    isNewMargin ? s.dropdown : s.oldDropdown;

  public readonly renderAllDropdownOptions = (dropdownOptions: IProductOption[]) => {
    const {variantsAvailability, globals} = this.props;
    const {isOptionsRevealEnabled: shouldIncludeVisibleKey} = globals;
    const shouldUseNewMargin = this.props.globals.useExperiments.enabled(
      Experiments.EnableQualityOptionsStylingChanges
    );

    return dropdownOptions.map((option) =>
      this.wrapAnimaiton(
        <div
          key={`${option.id}${shouldIncludeVisibleKey ? this.isVisible(option) : ''}`}
          className={classNames(this.getDropDownOptionClass(shouldUseNewMargin), this.getVisibilityClassNames(option))}
          data-hook={DataHook.OptionWrapper}>
          {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
        </div>,
        this.isVisible(option),
        this.isFirst(option)
      )
    );
  };

  private wrapAnimaiton(child: ReactNode, isVisible: boolean, preserveSpace: boolean): ReactNode {
    return this.props.globals.isOptionsRevealEnabled ? (
      <RevealAnimation isVisible={isVisible} preserveSpace={preserveSpace} data-hook={DataHook.RevealAnimationWrapper}>
        {child}
      </RevealAnimation>
    ) : (
      child
    );
  }

  private isOptionSelected(option: IProductOption): boolean {
    return option.selections.find(({id}) => this.props.selectionIds.includes(id)) !== undefined;
  }

  private isFirst(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    return options.length && options[0].id === option.id;
  }

  private isVisible(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    const index = options.findIndex(({id}) => option.id === id);
    return (
      this.isOptionSelected(option) ||
      (this.isFirst(option) && this.props.isItemHovered) ||
      (options[index] && this.isOptionSelected(options[index]))
    );
  }

  private isNextVisible(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    const index = options.findIndex(({id}) => option.id === id);
    return options[index + 1] && this.isVisible(options[index + 1]);
  }

  public render() {
    const shouldEnableShowColorOptionsOnlyExperiment = this.props.globals.useExperiments.enabled(
      Experiments.ShouldEnableShowColorOptionsOnly
    );
    const options = this.props.product.options;
    const colorOptions = getAllVisibleColorOptions(options);
    const dropdownOptions = getAllVisibleDropdownOptions(options);

    return (
      <>
        {this.renderAllColorOptions(colorOptions)}
        {shouldEnableShowColorOptionsOnlyExperiment ? (
          <ConditionalRender by={'showDropdownOptions'}>
            {this.renderAllDropdownOptions(dropdownOptions)}
          </ConditionalRender>
        ) : (
          this.renderAllDropdownOptions(dropdownOptions)
        )}
      </>
    );
  }
}

export const ProductOptionsWithGlobals = withGlobals(withTranslations()(ProductOptions));
